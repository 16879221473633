import React from "react"
import { Link } from "gatsby"

import ServiceImage1 from "../../assets/images/services-image/service-image1.png"
import ServiceImage2 from "../../assets/images/services-image/service-image2.png"
import ServiceImage3 from "../../assets/images/services-image/service-image3.png"
import ServiceImage4 from "../../assets/images/services-image/service-image4.png"
import ServiceImage5 from "../../assets/images/services-image/service-image5.png"
import ServiceImage6 from "../../assets/images/services-image/service-image6.png"

// Shape Images
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"
import Shape3 from "../../assets/images/shape3.svg"
import Shape4 from "../../assets/images/shape4.svg"

const OurServices = () => {
  return (
    <div className="ml-services-area ptb-80">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Our Solutions</span>
          <h2>Our Services</h2>
          <div className="bar"></div>
          <p>
            With our expertise and experience, we provide a seamless and
            streamlined approach to IT automation, allowing you to focus on your
            core business while we take care of the technical details.
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage1} alt="image" />
              </div>
              <h3>
                <Link to="#">Automation Consulting</Link>
              </h3>
              <p>
                We provide expert advice and guidance tailored to your needs and
                goals, covering licensing, the best tools, and implementation
                strategies.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage2} alt="image" />
              </div>
              <h3>
                <Link to="#">Implementation & Delivery</Link>
              </h3>
              <p>
                Our capabilities in RPA ensure seamless build and deployment,
                enabling your bots to be up and running with minimal turnaround
                time.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage3} alt="image" />
              </div>
              <h3>
                <Link to="#">Managed Services</Link>
              </h3>
              <p>
                Maximize automation benefits and stay ahead with our technical
                guidance and proactive maintenance, to focus on your core
                business.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage4} alt="image" />
              </div>
              <h3>
                <Link to="#">Integration & RAAS</Link>
              </h3>
              <p>
                Access cutting-edge RPA technology without upfront costs or
                technical ownership, making automation more affordable and
                accessible.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage5} alt="image" />
              </div>
              <h3>
                <Link to="#">
                  Intelligent Document Processing
                </Link>
              </h3>
              <p>
                Streamline your document-driven operations & reduce manual labor
                with our advanced AI-ML algorithms, OCR giving you a competitive
                edge.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage6} alt="image" />
              </div>
              <h3>
                <Link to="#">Monitoring & Insights</Link>
              </h3>
              <p>
                We set up control rooms for complete monitoring of automation
                and its resources, with data showcased on dashboards & timely
                alerts.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape2 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape3">
        <img src={Shape3} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </div>
  )
}

export default OurServices
