import React from 'react';
import { Link } from "gatsby";

import MLMainPic from "../../assets/images/ml-banner-image/ml-main-pic.png";

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";

const MainBanner = () => {
    return (
        <div className="ml-main-banner">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-banner-content">
                            <h1>Revolutionizing Business with Innovative AI & Automation</h1>
                            <p>We offer businesses cutting-edge technologies to streamline operations and increase productivity, helping them stay ahead of the curve. Our services are designed to enhance efficiency and drive growth, unlocking their full potential in the rapidly evolving digital landscape.</p>

                            <Link to="/contact" className="btn btn-secondary">
                                Get Started
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="ml-banner-single-image">
                            <img 
                                src={MLMainPic} 
                                className="animate__animated animate__fadeInUp animate__delay-0.5s" 
                                alt="image" 
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default MainBanner;