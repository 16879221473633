import React from 'react';
import { Link } from "gatsby";

import AboutImg from "../../assets/images/about4.png";

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";

const AboutUsContent = () => {
    return (
        <div className="about-area pt-0 ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-img">
                            <img src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-content">
                            <span className="sub-title">About Us</span>
                            <h2>We don’t just automate processes, we transform businesses</h2>
                            <div className="bar"></div>
                            <p>We believe in the KISS principle (Keep It Simple, Stupid) and strive to simplify complex solutions for our clients. Our vision is to always put our customers first and help them achieve their goals.</p>
                            <p>At our company, we take a customer-centric approach and collaborate closely with our clients, whether they are small startups or large corporations. Using our skills and expertise, we develop custom solutions that enable businesses to automate their workflows, improve productivity, and gain a competitive edge.</p>

                            <Link to="#" className="btn btn-secondary">
                                Discover More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default AboutUsContent;