import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

// Blog Images
import BlogImg9 from "../../assets/images/blog-image/blog9.jpg";
import BlogImg10 from "../../assets/images/blog-image/blog10.jpg";
import BlogImg11 from "../../assets/images/blog-image/blog11.jpg";

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg"; 

const BlogCard = () => {
    return (
        <div className="blog-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Our Recent Story</h2>
                    <div className="bar"></div>
                    <p>Stay up-to-date with the case studies, latest trends and insights in AI, Automation & other cutting-edge technologies through our informative blog articles.</p>
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <Link to="#">
                                    <img src={BlogImg9} alt="image" />
                                </Link>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Link to="#">Admin</Link>
                                        </li>
                                        <li>March 01, 2023</li>
                                    </ul>
                                </div>

                                <h3>
                                    <Link to="#">
                                        Introduction to RPA and its Benefits
                                    </Link>
                                </h3>
                                <p>RPA uses software robots or bots to automate repetitive and mundane tasks that are typically done by humans, such as data entry, data mining, and data processing...</p>

                                <Link to="#" className="learn-more-btn">
                                    Read Story <Icon.Plus />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <Link to="#">
                                    <img src={BlogImg10} alt="image" />
                                </Link>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Link to="#">Admin</Link>
                                        </li>
                                        <li>March 10, 2023</li>
                                    </ul>
                                </div>

                                <h3>
                                    <Link to="#">
                                    RPA with Artificial intelligence
                                    </Link>
                                </h3>
                                <p>AI, on the other hand, is a technology that enables machines to learn from data and perform tasks that typically require human intelligence, such as language recognition, decision making...</p>
                                
                                <Link to="#" className="learn-more-btn">
                                    Read Story <Icon.Plus />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post-box">
                            <div className="entry-thumbnail">
                                <Link to="#">
                                    <img src={BlogImg11} alt="image" />
                                </Link>
                            </div>

                            <div className="entry-post-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Link to="#">Admin</Link>
                                        </li>
                                        <li>March 21, 2023</li>
                                    </ul>
                                </div>

                                <h3>
                                    <Link to="#">
                                        The future of RPA: Predictions & Trends
                                    </Link>
                                </h3>
                                <p>We are seeing more and more organizations incorporating AI and ML into their RPA workflows to achieve greater efficiency and accuracy. This trend is likely to continue & evolve.</p>
                                
                                <Link to="#" className="learn-more-btn">
                                    Read Story <Icon.Plus />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default BlogCard;